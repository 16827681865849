import React, { useEffect, useState, lazy, Suspense } from "react";
import Header from "./Header";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import RemoveOrderModal from "./reusable/RemoveOrderModal";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";

import Modal from "@mui/material/Modal";
import { TiInfoLarge } from "react-icons/ti";
import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import OTPInput, { ResendOTP } from "otp-input-react";
import Footer from "./Footer";
import MapboxRobot from "./MapboxRobot";
import Instruction from "../modals/Instruction";
import CustomProgressBar from "./reusable/CustomProgressBar";
import axios from "axios";
import { toast } from "react-toastify";
const Watchvideo = lazy(() => import("../modals/Watchvideo"));

function Congratulations({
  maxBoundCordinates,
  orderDetails,
  otp,
  eta,
  etaLoader,
  isLoading,
}) {
  const [openRemoveOrderModal, setOpenRemoveOrderModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { orderID } = useParams();
  const [open, setOpen] = React.useState(false);
  const [openthree, setopenthree] = useState(false);
  const [opentwo, setOpentwo] = React.useState(false);
  const navigate = useNavigate();
  const [inputOTP, setInputOTP] = useState("");
  const handleOpenthree = () => setopenthree(true);
  const [openfour, setopenfour] = React.useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || "#00A16F";
  const secondaryColor = process.env.REACT_APP_SECONDARY_COLOR || "#D9FAF1";
  const handleOpenfour = () => {
    setopenfour(true);
  };
  const deleteOrder = async (id) => {
    try {
      const deleteAction = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/order/cancel`,
        {
          fleetId: orderDetails.fleetId,
          orderId: id,
          message: "order removed",
          canceledByCustomer: true,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BACKEND_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(deleteAction);
      toast.success(deleteAction.data.message);
    } catch (err) {
      toast.error(err.message);
    }
  };
  var rString = "";

  const loaderContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "10vh",
  };

  const loaderStyle = {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderTop: "4px solid #000",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    animation: "spin 1s linear infinite",
  };

  const keyframesAnimation = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "#ffffff",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    paddingTop: "11px",
    paddingBottom: "0px",
    border: "none",
    paddingRight: "0px",
    paddingLeft: "0px",
    zIndex: "999999999999999999999999",
  };

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const handleClosetwo = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpentwo(false);
  };
  const setupOrderStatus = (status) => {
    let response = {};
    switch (status) {
      case 5:
        response.text = "Order Picked Up";
        response.subText = "";
        response.imageSrc = "/assets/images/image_dummy.png";

      case 6:
        response.text = "Order Enroute Delivery Location";
        response.imageSrc = "/assets/images/noun-location-5474996.svg";
        return response;
      case 3:
        response.text = "Order reached delivery location";
        response.subText = "";
        response.imageSrc = "/assets/images/noun-location-5474996.svg";
      case 4:
        response.text = "Congratulations ! Your order has been completed";
        response.imageSrc = "/assets/images/noun-location-5474996.svg";
    }
  };

  const handleOpen = () => setOpen(true);

  const handleopentwo = () => setOpentwo(true);
  let mapheight = "327px";

  useEffect(() => {
    if (document.getElementById("Estimated_time")) {
      var offsetHeight = document.getElementById("Estimated_time").offsetHeight;
    }

    mapheight = mapheight - offsetHeight + "px";
  }, [mapheight, otp]);

  function generateBarCode(qrcode) {
    var url =
      "https://api.qrserver.com/v1/create-qr-code/?data=" +
      qrcode +
      "&amp;size=50x50";
    $("#barcode").attr("src", url);
    return rString;
  }
  useEffect(() => {
    const progressBarPercentage = () => {
      let etaToBeShown = eta?.pickupEta;
      if (orderDetails?.statusCode >= 5) {
        etaToBeShown = eta?.totalEta;
      }
      if (!etaToBeShown) {
        return 100;
      }
      if (!orderDetails?.ETA) {
        return 50;
      }
      let calcPercentage =
        ((orderDetails?.ETA - etaToBeShown) / orderDetails?.ETA) * 100;
      if (calcPercentage < 0) {
        return 0;
      }
      if (calcPercentage > 100) {
        return 100;
      }
      return calcPercentage;
    };

    // const orderFlag = () => {
    //   let flag;
    //   let timeTakenByTheOrder =
    //     (new Date() - new Date(orderDetails.order_dispatched)) / 1000.0;
    //   let timeDiff = orderDetails?.ETA - timeTakenByTheOrder;
    //   if (timeDiff >= 0) {
    //   } else {
    //   }
    // };
    const currentProgress = progressBarPercentage();
    setProgressBar(currentProgress);
  }, [eta, orderDetails]);

  const getorderpage = (orderDetails) => {
    if (!!Number(process.env.REACT_APP_ONLYPICKUP)) {
      if (orderDetails.statusCode > 5) {
        if (orderDetails.statusCode === 10) {
          navigate("/ordercancel", { state: { orderDetails, orderID } });
        } else {
          navigate("/orderpickedup", { state: { orderDetails, orderID } });
        }
      }
    } else {
      if (orderDetails?.orderStatus === "Order is delivered (Complete)") {
        navigate("/orderdelivered", { state: { orderDetails, orderID } });
      } else if (orderDetails?.statusCode === "Order is Cancelled") {
        navigate("/ordercancel", { state: { orderDetails, orderID } });
      }
    }
  };
  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div className="MainWhole-wrapper-container">
        <Header orderDetails={orderDetails} open={open} opentwo={opentwo} />
        <div className="Congratulation-Main-wrapper">
          <div className="flex flex-col h-full">
            <div
              className="h-1/2 bg-white Congralutions_page_map_div"
              id="mapDiv"
            >
              {orderDetails?.orderDropLocation?.positionY === undefined ||
                orderDetails?.orderDropLocation?.positionX === undefined ||
                orderDetails?.orderPickupLocation?.positionY === undefined ||
                orderDetails?.orderPickupLocation?.positionX === undefined ||
                orderDetails?.orderStatus === "Order is Cancelled" ||
                (orderDetails?.orderStatus ===
                  "Order is delivered (Complete)" &&
                  getorderpage(orderDetails))}
              {Object.keys(maxBoundCordinates).length ? (
                <MapboxRobot
                  orderDetails={orderDetails}
                  maxBoundCordinates={maxBoundCordinates}
                  orderDropLocation={orderDetails?.orderDropLocation}
                  orderPickupLocation={orderDetails?.orderPickupLocation}
                  robotLocation={orderDetails?.robotLocation}
                />
              ) : null}
            </div>
            <div className="flex flex-col gap-y-3 rounded-t-full py-3 px-4">
              <div className="flex flex-col gap-y-2">
                <div className="flex justify-between">
                  <p className="font-bold self-center text-base">
                    {orderDetails?.statusCode >= 5
                      ? eta?.totalEta && eta?.totalEta > 0
                        ? `Delivery in ${
                            Math.ceil(eta?.totalEta / 60) ?? 0
                          } mins`
                        : "Reached"
                      : eta?.pickupEta && eta?.pickupEta > 0
                      ? `Pickup in ${Math.ceil(eta?.pickupEta / 60) ?? 0} mins`
                      : "Reached"}
                  </p>
                  {orderDetails?.statusCode < 5 && (
                    <button
                      className="text-xs text-white p-2 bg-red-500 font-bold rounded"
                      onClick={() => setOpenRemoveOrderModal(true)}
                    >
                      Cancel Pickup
                    </button>
                  )}
                </div>
                <CustomProgressBar value={progressBar} />
              </div>

              <div className="Congratulations_page_QRcode_pin_wrapper">
                <p className="text-xl font-bold">PIN to access Cabin</p>
                <div className="QR-Code-main-inner">
                  <OTPInput
                    value={localStorage.getItem("pin")}
                    inputClassName="readonly-input"
                    onChange={setInputOTP}
                    OTPLength={localStorage.getItem("pin")?.length ?? 4}
                    onKeyDown={handleKeyDown}
                    otpType="number"
                    disabled={false}
                    className="Congratulation_page_otp_input"
                  />
                </div>
              </div>
              <div className="flex border border-[#E6E6E6] py-3 px-2 gap-x-2 rounded w-full">
                <div className="bg-[#F1F0F5] py-1.5 px-3 rounded flex">
                  <svg
                    className="self-center"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="32.956"
                    viewBox="0 0 15 32.956"
                  >
                    <g
                      id="noun-info-2096294"
                      transform="translate(-222.749 -0.058)"
                    >
                      <path
                        id="Path_85"
                        data-name="Path 85"
                        d="M233.156,146.716c2.733,0,4.074,1.742,3.293,4.627-.557,2.088-1.363,4.113-2.1,6.151-.813,2.266-1.676,4.518-2.5,6.78a6.843,6.843,0,0,0-.365,1.475,1,1,0,0,0,.244.813.938.938,0,0,0,.843,0,6.254,6.254,0,0,0,1.35-1.034c.846-.86,1.646-1.782,2.466-2.654.247-.26.5-.724.988-.418a.863.863,0,0,1,.171,1.281,12.978,12.978,0,0,1-1.291,1.821,15.992,15.992,0,0,1-7.9,5.216,4.922,4.922,0,0,1-2.914.079h0a2.8,2.8,0,0,1-1.367-.915,3.229,3.229,0,0,1-.514-2.164,6.291,6.291,0,0,1,.389-2.088s1.1-2.914,2.634-7.1c1.317-3.6,1.459-4.192,1.884-5.308.211-.553.435-1.1.659-1.646.161-.441.352-.988-.115-1.248a1.433,1.433,0,0,0-1.245.138,15.8,15.8,0,0,0-1.976,1.8c-.616.629-1.159,1.317-1.758,1.976-.254.273-.553.55-.968.24s-.356-.659-.161-1.06h0a1.183,1.183,0,0,1,.122-.23c2.305-3.1,4.979-5.684,8.986-6.385a4.815,4.815,0,0,1,1.149-.148Z"
                        transform="translate(0 -138.034)"
                      />
                      <path
                        id="Path_86"
                        data-name="Path 86"
                        d="M301.2,3.594a4.192,4.192,0,0,1-5.713,3.846,3.518,3.518,0,0,1-1.867-4.248h0A4.281,4.281,0,0,1,298.3.094a3.312,3.312,0,0,1,2.9,3.5Z"
                        transform="translate(-66.577 0)"
                        fill="#00a16f"
                      />
                    </g>
                  </svg>
                  {/* <TiInfoLarge className="h-12 w-12 self-center" /> */}
                </div>
                <div className="flex flex-col gap-y-1 w-full">
                  <div className="flex text-[#00A16F] justify-between">
                    <p className="font-bold text-xs self-center">
                      {orderDetails?.robotName}
                    </p>
                    <button
                      onClick={handleOpenthree}
                      className="text-xs p-1.5 bg-[#F1F0F5] font-bold rounded"
                    >
                      VIEW INSTRUCTIONS
                    </button>
                  </div>
                  <p className="self-start font-bold text-sm">OTTOBOT</p>
                  <p className="self-start text-sm capitalize">
                    {orderDetails?.orderStatus}
                  </p>
                </div>
              </div>
              {/* <div className="!p-4 mx-3 bg-white border-2 rounded-lg border-ottonomyBlue Congratulation-Main-wrapper_inner sm:!p-5">
              <div className="flex flex-col">
                <p className="Congratulation-Main-wrapper_p">
                  An Ottobot will be delivering your order.
                </p>
              </div>

              <div className="flex justify-center gap-[10px] congratulations_button_wrapper">
                <button
                  className="rounded-lg bg-ottonomyBlue congratulations_page_instruction_btn py-1.5 px-2.5 sm:px-[30px]"
                  onClick={handleOpenthree}
                >
                  Instructions
                </button> */}
              {/* <button
                  className="rounded-lg bg-ottonomyBlue congratulations_page_instruction_btn py-1.5 px-2.5 sm:px-[30px]"
                  onClick={handleOpenfour}
                >
                  Watch Video
                </button> */}
              {/* </div>
            </div> */}

              {/* <div className="Congratulation-page-order-wrapper-below-QR">
              <div>
                <h5>Robot</h5>
                <p> Ottobot </p>
              </div>
            </div> */}
            </div>
          </div>

          <Modal
            open={open}
            // BackdropProps={false}
            onHide={handleClose}
            // onClose={handleClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="QR-Code-wrapper-whole">
                <div className="QR-Code-wrapper-whole-inner-one">
                  <h3> Scan QR Code</h3>
                  <div
                    className="Sorry_div_wrapper_inner_div"
                    style={{ paddingBottom: "0px" }}
                    onClick={handleClose}
                  >
                    <HighlightOffIcon
                      style={{ fontSize: "35px", margin: "5px" }}
                    />
                    <p style={{ fontSize: "17px" }}>Close</p>
                  </div>
                </div>
                <p style={{ marginBottom: "12px" }}>
                  {" "}
                  Show the QR code to the camera
                </p>
                <div className="QR-Code-main-inner">
                  {generateBarCode(orderDetails?.qrcode)}
                  <img
                    id="barcode"
                    src="https://api.qrserver.com/v1/create-qr-code/?data=Congratulation&amp;size=100x100"
                    alt=""
                    title="Congratualtions"
                    width="500"
                    height="500"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={opentwo}
            onHide={handleClosetwo}
            backdrop="static"
            keyboard={false}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="QR-Code-wrapper-whole">
                <div className="QR-Code-wrapper-whole-inner-one">
                  <h3> Your PIN</h3>
                  <div
                    className="Sorry_div_wrapper_inner_div"
                    style={{ paddingBottom: "0px" }}
                    onClick={handleClosetwo}
                  >
                    <HighlightOffIcon
                      style={{ fontSize: "35px", margin: "5px" }}
                    />
                    <p style={{ fontSize: "17px" }}>Close</p>
                  </div>
                </div>
                <p style={{ marginBottom: "12px" }}>
                  {" "}
                  Enter the PIN on the Top Screen
                </p>
                <div className="QR-Code-main-inner">
                  <OTPInput
                    value={otp}
                    onChange={setInputOTP}
                    autoFocus
                    OTPLength={localStorage.getItem("pin")?.length ?? 4}
                    otpType="number"
                    disabled={false}
                    className="Cart_page_otp_input"
                  />
                </div>
              </div>
            </Box>
          </Modal>

          <div className="Order_Details_div"></div>
        </div>
      </div>
      <Instruction
        openthree={openthree}
        setopenthree={setopenthree}
        handleOpenthree={handleOpenthree}
      />
      <Suspense
        fallback={
          <div style={loaderContainerStyle}>
            <style>{keyframesAnimation}</style>
            <div style={loaderStyle}></div>
          </div>
        }
      >
        {openfour && (
          <Watchvideo
            openfour={openfour}
            setopenfour={setopenfour}
            handleOpenfour={handleOpenfour}
          />
        )}
      </Suspense>
      <RemoveOrderModal
        openRemoveOrderModal={openRemoveOrderModal}
        setOpenRemoveOrderModal={setOpenRemoveOrderModal}
        deletedOrderId={orderID}
        deleteOrder={deleteOrder}
      />
      <Footer eta={Math.ceil(eta / 60)} etaLoader={etaLoader} />
    </>
  );
}

export default Congratulations;
